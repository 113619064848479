/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

/* Main Routes Data */

import React from "react"
import { ValidateGoogleAuth } from "../components/ValidateGoogleAuth"
import { Redirect, Route } from "react-router-dom"
import { UseProcessorView } from "./models"

const AuditDashboardView = React.lazy(() => import('../pages/audit/Dashboard/AuditDashboardView').then(module => ({default: module.AuditDashboardView})))
const AuditMerchantView = React.lazy(() => import('../pages/audit/Merchant/AuditMerchantView').then(module => ({default: module.AuditMerchantView})))

const DashboardPage = React.lazy(() => import('../pages/DashboardPage/DashboardPage').then(module => ({default: module.DashboardPage})))

// all external in vite-admin.config.js for admin app
const CollectiveView = React.lazy(() => import('../pages/CollectiveView/CollectiveView').then(module => ({default: module.CollectiveView})))
const CollectiveEditProfileView = React.lazy(() => import('../pages/CollectiveView/CollectiveEditProfileView').then(module => ({default: module.CollectiveEditProfileView})))
const MerchantView = React.lazy(() => import('../pages/MerchantView/MerchantView').then(module => ({default: module.MerchantView})))
const MerchantEditProfileView = React.lazy(() => import('../pages/MerchantView/MerchantEditProfileView').then(module => ({default: module.MerchantEditProfileView})))
const MerchantExtensionToken = React.lazy(() => import('../pages/MerchantView/MerchantExtensionToken').then(module => ({default: module.MerchantExtensionToken})))
const BulkJobView = React.lazy(() => import('../pages/BulkJobView/BulkJobView').then(module => ({default: module.BulkJobView})))
const ResourceGroupView = React.lazy(() => import('../pages/ResourceGroupView/ResourceGroupView').then(module => ({default: module.ResourceGroupView})))

// admin related references
const AdminPage = React.lazy(() => import('../pages/admin/AdminPage').then(module => ({default: module.AdminPage})))
const InternalSupportPage = React.lazy(() => import('../pages/admin/InternalSupportPage').then(module => ({default: module.InternalSupportPage})))
const AdminInvoiceView = React.lazy(() => import('../pages/admin/InvoiceView').then(module => ({default: module.AdminInvoiceView})))
const TestDataPage = React.lazy(() => import('../pages/admin/TestDataPage').then(module => ({default: module.TestDataPage})))
const CollectivePage = React.lazy(() => import('../pages/admin/CollectivePage').then(module => ({default: module.CollectivePage})))
const MerchantPage = React.lazy(() => import('../pages/admin/MerchantPage').then(module => ({default: module.MerchantPage})))
const ServerStatsPage = React.lazy(() => import('../pages/admin/ServerStatsPage').then(module => ({default: module.ServerStatsPage})))

const BinServiceView = React.lazy(() => import("../pages/BinServiceView/BinServiceView").then(module => ({default: module.BinServiceView})))
const MerchantFeeRoutingView = React.lazy(() => import("../pages/MerchantView/MerchantFeeRoutingView").then(module => ({default: module.MerchantFeeRoutingView})))
const MerchantFeeServView = React.lazy(() => import("../pages/MerchantView/MerchantFeeServView").then(module => ({default: module.MerchantFeeServView})))

const TxLogDetailView = React.lazy(() => import("../pages/TxLogDetailView/TxLogDetailView").then(module => ({default: module.TxLogDetailView})))
const ProcessorView = React.lazy(() => import('../pages/ProcessorView/ProcessorView').then(module => ({default: module.ProcessorView})))
const ProcessorDimensionView = React.lazy(() => import('../pages/ProcessorDimensionView/ProcessorDimensionView').then(module => ({default: module.ProcessorDimensionView})))

// paylink references
const PaylinkSandboxView = React.lazy(() => import('../pages/paylink/PaylinkSandboxView').then(module => ({default: module.PaylinkDashboardView})))
const PaylinkVirtualTerminalView = React.lazy(() => import('../pages/paylink/PaylinkVirtualTerminalView').then(module => ({default: module.PaylinkVirtualTerminalView})))

export type DomainNamespace =
  | "home"
  | "contextFree"
  | "collectiveRoot"
  | "merchantRoot"
  | "collectiveSubpage"
  | "merchantSubpage"
  | "binServiceRoot"
  | "feeServiceRoot"
  | "resourceGroupRoot"

type ExactMatchConfig = { exact?: boolean }

type RouteRenderableComponent = { component: any, render?: never } // we use never to enforce an either-or: either we use a render callback or a component
type RouteRenderableCallback = { render: (props: any) => any, component?: never }
type RouteRenderable = RouteRenderableComponent | RouteRenderableCallback

export type RouteConfig = {
  path: string,
  namespace?: DomainNamespace,
  name: string,
} & RouteRenderable & ExactMatchConfig

export type RedirectConfig = {
  from: string,
  to: string,
} & ExactMatchConfig

export type RouterObjectConfig = RouteConfig | RedirectConfig

export const dashboardRoute:RouterObjectConfig[] = [
  {
    path: '/p',
    component: DashboardPage,
    namespace: 'home',
    name: 'User Dashboard',
    exact: true,
  }
]

const baseRoutes:RouterObjectConfig[] = [
  {
    path: '/p/collective',
    component: CollectivePage,
    namespace: 'contextFree',
    name: 'All Collectives',
    exact: true,
  },
  {
    path: '/p/merchant',
    render: (props:any) => <MerchantPage isAdmin={true} />,
    namespace: 'contextFree',
    name: 'All Merchants',
    exact: true,
  },
  {
    path: '/p/merchant/extension/:extensionType',
    render: (props:any) => <MerchantExtensionToken />,
    namespace: 'contextFree',
    name: 'Create Extension Token',
    exact: false,
  },
  {
    path: '/p/external/googleauth',
    render: (props:any) => <ValidateGoogleAuth />,
    namespace: 'contextFree',
    name: 'Authorize To Google',
    exact: true,
  }
]

const collectiveRoutes:RouterObjectConfig[] = [
  {
    path: '/p/collective/:cid/:callbackTab/:callback',
    render: (props:any) => <CollectiveView />,
    namespace: 'collectiveSubpage',
    name: 'Collective Directed View',
  },
  {
    path: '/p/collective/:cid',
    component: CollectiveView,
    namespace: 'collectiveRoot',
    name: 'Collective View',
  },
]

const binServiceRoutes: RouterObjectConfig[] = [
  {
    path: '/p/collective/:cid',
    render: (props:any) => <BinServiceView />,
    namespace: 'binServiceRoot',
    name: 'Bin Service',
    exact: true,
  }
]

const feeServiceRoutes: RouterObjectConfig[] = [
  {
    path: '/p/merchant/:mid/routing',
    render: (props: any) => <MerchantFeeRoutingView />,
    namespace: 'merchantSubpage',
    name: 'Smart Routing View',
    exact: true,
  },
  {
    path: '/p/merchant/:mid',
    render: (props: any) => <MerchantFeeServView />,
    namespace: 'merchantRoot',
    name: 'Merchant View',
    exact: true,
  },
]

const auditRoutes: RouterObjectConfig[] = [
  {
    path: '/p',
    component: AuditDashboardView,
    namespace: 'home',
    name: 'Audit Dashboard',
    exact: true,
  },
  {
    path: '/p/merchant/:mid',
    component: AuditMerchantView,
    namespace: 'merchantRoot',
    name: 'Audit Merchant View',
    exact: true,
  },
]

const processorViewRoutes:RouterObjectConfig[] = [
  {
    path: '/p/merchant/:mid/processor/:pid',
    render: (props: any) => <ProcessorView />,
    namespace: 'merchantSubpage',
    name: 'View Processor Detail',
    exact: true,
  },
]

const merchantRoutes:RouterObjectConfig[] = [
  {
    path: '/p/merchant/:mid',
    component: MerchantView,
    namespace: 'merchantRoot',
    name: 'Merchant View',
    exact: true,
  },
  {
    path: '/p/merchant/:mid/txlog/:txid',
    render: (props:any) => <TxLogDetailView />,
    namespace: 'merchantSubpage',
    name: 'View Transaction Detail',
    exact: true,
  },
  {
    path: '/p/merchant/:mid/processor/dimension/:pdid',
    render: (props: any) => <ProcessorDimensionView />,
    namespace: 'merchantSubpage',
    name: 'View Processor Dimension Detail',
    exact: true,
  },
  ...(UseProcessorView ? processorViewRoutes : []),
  {
    path: '/p/merchant/:mid/bulk/:filename',
    render: (props: any) => <BulkJobView />,
    namespace: 'merchantSubpage',
    name: 'View Bulk Job Detail',
    exact: true,
  },
  {
    path: '/p/merchant/:mid/extension/:extensionType',
    render: (props:any) => <MerchantExtensionToken />,
    namespace: 'contextFree',
    name: 'Create Extension Token',
    exact: true,
  },
]

const resourceGroupRoutes:RouterObjectConfig[] = [
  {
    path: '/p/group/:rgid',
    component: ResourceGroupView,
    namespace: 'resourceGroupRoot',
    name: 'Resource Group View',
    exact: true,
  }
]

// do we want or need a new "admin" set of routes
const adminRoutes2:RouterObjectConfig[] = [
  {
    path: '/p/admin',
    component: AdminPage,
    namespace: 'contextFree',
    name: 'Admin View',
    exact: true,
  },
  {
    path: '/p/status',
    component: ServerStatsPage,
    namespace: 'contextFree',
    name: 'Server Status',
    exact: true,
  },
  {
    path: '/p/support',
    component: InternalSupportPage,
    namespace: 'contextFree',
    name: 'Internal Support',
    exact: true,
  },
  {
    path: '/p/admin/invoice',
    component: AdminInvoiceView,
    namespace: 'contextFree',
    name: 'Invoicing',
    exact: true,
  },
  {
    path: '/p/m/admin/testdata',
    component: TestDataPage,
    namespace: 'contextFree',
    name: 'Test Data',
    exact: true,
  }
]

const adminRoutes:RouterObjectConfig[] = [
  {
    path: '/p/admin',
    component: AdminPage,
    namespace: 'contextFree',
    name: 'Admin View',
    exact: true,
  },
  {
    path: '/p/support',
    component: InternalSupportPage,
    namespace: 'contextFree',
    name: 'Internal Support',
    exact: true,
  },
  {
    path: '/p/admin/invoice',
    component: AdminInvoiceView,
    namespace: 'contextFree',
    name: 'Invoicing',
    exact: true,
  },
  {
    path: '/p/m/admin/testdata',
    component: TestDataPage,
    namespace: 'contextFree',
    name: 'Test Data',
    exact: true,
  },
  {
    path: '/p/collective/create',
    render: (props:any) => <CollectiveEditProfileView />,
    namespace: 'contextFree',
    name: 'Create Collective',
    exact: true,
  },
  {
    path: '/p/merchant/create',
    render: (props:any) => <MerchantEditProfileView />,
    namespace: 'contextFree',
    name: 'Create Merchant',
    exact: true,
  },
]

const paylinkRoutes: RouterObjectConfig[] = [
  {
    path: '/p/paylink/sandbox',
    component: PaylinkSandboxView,
    namespace: 'home',
    name: 'Paylink Dashboard',
    exact: true,
  },
  {
    path: '/p/paylink/vt',
    component: PaylinkVirtualTerminalView,
    namespace: 'contextFree',
    name: 'Paylink Virtual Terminal',
    exact: true
  }
]

export const mainRoutes:RouterObjectConfig[] = [
  ...adminRoutes,
  ...dashboardRoute,
  ...paylinkRoutes,
  ...baseRoutes,
  ...resourceGroupRoutes,
  ...collectiveRoutes,
  ...merchantRoutes,
]

export const binservRoutes:RouterObjectConfig[] = [
  ...adminRoutes,
  ...dashboardRoute,
  ...binServiceRoutes
]

export const feeservRoutes:RouterObjectConfig[] = [
  ...adminRoutes,
  ...dashboardRoute,
  ...collectiveRoutes, // TODO remove existing collective routes and replace with FeeServCollectiveView
  ...feeServiceRoutes,
]

export const auditPortalRoutes:RouterObjectConfig[] = [
  ...adminRoutes,
  ...auditRoutes,
]

export const adminPortalRoutes:RouterObjectConfig[] = [
  ...adminRoutes2,
  ...dashboardRoute,
]

export const allRoutes:RouterObjectConfig[] = [
  ...adminRoutes,
  ...dashboardRoute,
  ...baseRoutes,
  ...collectiveRoutes,
  ...merchantRoutes,
  ...binServiceRoutes,
  ...paylinkRoutes,
]

export const buildRouteAsJSX = (route: RouterObjectConfig) => {
  const isRedirect = (obj: any): obj is RedirectConfig => !!obj.to && !!obj.from
  const isRoute = (obj: any): obj is RouteConfig => !!obj.path

  if (isRedirect(route)) return <Redirect key={route.from} from={route.from} to={route.to} exact={route.exact || false} />
  else if (isRoute(route)) {
    const hasComponent = !!route.component
    const hasRender = !!route.render
    if (hasComponent) return <Route key={route.path} path={route.path} component={route.component} exact={route.exact || false} />
    else if (hasRender) return <Route key={route.path} path={route.path} render={route.render} exact={route.exact || false} />
  }
}
