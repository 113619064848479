/*
 * InterPayments Inc. ("COMPANY") CONFIDENTIAL
 * Unpublished Copyright © 2023 InterPayments Inc., All Rights Reserved.
 *
 * https://interpayments.com/copyright-policy/
 *
 * NOTICE: All information contained herein is, and remains the property of
 * COMPANY. The intellectual and technical concepts contained herein are
 * proprietary to COMPANY and may be covered by U.S. and Foreign Patents, patents
 * in process, and are protected by trade secret or copyright law. Dissemination
 * of this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from COMPANY. Access to the source
 * code contained herein is hereby forbidden to anyone except current COMPANY
 * employees, managers or contractors who have executed Confidentiality and
 * Non-disclosure agreements explicitly covering such access.
 *
 * The copyright notice above does not evidence any actual or intended publication
 * or disclosure of this source code, which includes information that is
 * confidential and/or proprietary, and is a trade secret, of COMPANY. ANY
 * REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY
 * OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT THE EXPRESS WRITTEN CONSENT
 * OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 * INTERNATIONAL TREATIES. THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR
 * RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE
 * OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 * MAY DESCRIBE, IN WHOLE OR IN PART.
 *
 */

import React, { useCallback, useEffect, useState } from 'react'
import {useParams} from 'react-router-dom'
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react"
import Loading from "../../components/Loading/Loading"
import {useService} from "../../providers/service-provider/service-provider"
import {useHistory} from "react-router-dom"

import { PageHeader } from '@ant-design/pro-layout';

import { Button, Card, Layout, Result, Space, Spin, Typography } from 'antd';
import {CollectiveService} from "../../services/collective-service"
import {Base64} from "js-base64"
import {MerchantService} from "../../services/merchant-service"
import { AppHeader } from '../../components/Layout/AppHeader'
import { AppFooter } from '../../components/Layout/AppFooter'
import { useMerchantAPI } from "../../hooks/useMerchantAPI"
import { useCollectiveAPI } from "../../hooks/useCollectiveAPI"
import {env2} from "../../utils/env2"

const { Content } = Layout

interface AcceptParams {
  cid: string,
  mid?: string,
  rid?: string,
  token: string,
  email: string
}

const ForceEmailMatching: boolean = env2("REACT_APP_FORCE_EMAIL_MATCHING", "true") === "true"

const InvitationRsvpFC: React.FC<React.PropsWithChildren<unknown>> = () => {

  const { logout, user, getIdTokenClaims } = useAuth0()

  const { cid, mid, rid, token, email } = useParams<AcceptParams>()

  const decodedEmail = Base64.decode(email)

  const { getCollectiveInviteMetadata } = useCollectiveAPI()
  const { getMerchantInviteMetadata } = useMerchantAPI()
  const collectiveService = useService(CollectiveService)
  const service = useService(MerchantService)
  const history = useHistory()

  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ resourceName, setResourceName ] = useState<string>('')

  const acceptInvite = useCallback(async () => {
    if (await collectiveService.isReady()) {
      let p
      if ((cid || rid) && !mid) {
        p = collectiveService.inviteRsvp(cid! || rid!, token)
      } else if (cid && mid) {
        p = collectiveService.inviteMerchantRsvp(cid, mid, token)
      } else if (!cid && mid) {
        p = service.inviteRsvp(mid, token)
      } else {
        console.error("no invitation sequence")
      }
      p && p.then(_ => history.push("/"))
      .catch((e: any) => {
        console.log("[InvitationRsvpFC] acceptInvite error: ", e)
      })
    }
  }, [collectiveService, service, cid, mid, rid, history])
  
  const denyInvite = useCallback(() => {
    console.log("TODO... send an email to support@interpayments to follow-up")
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
    })
  }, [logout])

  const loadInviteMetadata = useCallback(async () => {
    if (!!mid) {
      (async () => {
        getMerchantInviteMetadata(mid)
          .then(m => {
            setResourceName(m.name)
            setIsLoading(false)
          })
      })()    
    } else if (!!cid || !!rid) {
      (async () => {
        getCollectiveInviteMetadata(cid! || rid!)
          .then(c => {
            setResourceName(c.name)
            setIsLoading(false)
          })
      })()
    } else console.log('[InvitationRsvpFC] acceptInvite error: no collective or merchant given')
  }, [mid, cid])

  useEffect(() => {
    if (!!user) {
      const { email } = user
      if (ForceEmailMatching && (email!.toLowerCase() !== decodedEmail.toLowerCase())) {
        // if we are logged in as the wrong user, go back to InvitationAccept component
        history.replace(window.location.pathname.replace(/\/a$/, '/u'))
      } else {
        if (!ForceEmailMatching && email!.toLowerCase() !== decodedEmail.toLowerCase()) {
          getIdTokenClaims()
              .then(t => {
                console.log("email mismatch, validate results")
                if (t) {
                  console.log("email", t.email)
                  console.log("name", t.name)
                  console.log("nickname", t.nickname)
                }
              })
        }
      }
      loadInviteMetadata()
    }
  }, [user, getIdTokenClaims, loadInviteMetadata])

  return <>
    <Layout
      style={{minHeight:"100vh"}}
    >
      <AppHeader />
      <Content style={{padding: '0 56px'}}>
        {!!mid && <PageHeader title="InterPayments Invitation RSVP to Merchant" />}
        {!!cid && !mid && <PageHeader title="InterPayments Invitation RSVP to Collective" />}
        {!!rid && !mid && <PageHeader title="InterPayments Invitation RSVP to Resource Group" />}
        {isLoading && <Spin tip="Loading..." />}
        {!isLoading && <Card>
          <Result
            title={<>You have been invited to <Typography.Text strong>{resourceName}</Typography.Text></>}
            subTitle={`You may now choose whether to accept or decline the invitation.  If you accept, you will have access to the ${!!cid ? 'collective' : !!mid ? 'merchant' : 'resource'} forever, unless it is revoked.`}
            extra={<>
              <Space>
                <Button type={"primary"} onClick={() => acceptInvite()}>
                  Accept Invite
                </Button>
                <Button type={"default"} danger onClick={() => denyInvite()}>
                  Decline Invite
                </Button>
              </Space>
            </>}
          />
        </Card>}
      </Content>
      <AppFooter />
    </Layout>
  </>
}

export const InvitationRsvp = withAuthenticationRequired(InvitationRsvpFC, {
  onRedirecting: () => <Loading />,
})
